import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import Typography from '~/components/atoms/Typography'
import BrandLogo from '~/components/molecules/BrandLogo'
import BathGraphStoryFigure from '~/components/molecules/BathGraphStoryFigure'
import DualLink from '~/components/molecules/DualLink'
import ContentsMenu from '~/components/molecules/ContentsMenu'
import ServiceList from '~/components/organisms/ServiceList'
import NewsList from '~/components/organisms/NewsList'
import Button from '~/components/atoms/Button'

import {
	article,
	article_flex, article_flex_reverse,
	article_main,
	article_sub,
	article_text,
	article_title,
	bath_graph_figure, bath_graph_note,
	bg_grey,
	bg_primary,
	bg_story,
	brand_logo,
	concept_list_item,
	concept_list_text,
	concept_list_title,
	concept_title,
	container, contents_menu,
	dual_link, pc_hide,
	primary_text,
	section_title,
	sp_hide, story_category, story_description, story_title,
	story,
	text_link
} from './styles'
import { Container } from '~/components/layout/Container'


const Story = () => {
	const { story_bg } = useStaticQuery<GatsbyTypes.StoryQuery>(graphql`
		query Story {
		    story_bg: file(relativePath: {eq: "bg_story.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 1200) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		}
	`)
	return (
		<>
			<BackgroundImage
				css={bg_story}
				fluid={[
					story_bg.childImageSharp.fluid,
					`linear-gradient(180deg, #F0F3F5 0%, rgba(240, 243, 245, 0.503042) 49.7%, rgba(240, 243, 245, 0) 100%)`
				].reverse()}
			>
				<Container css={[container, story]}>
					<article css={article}>
						<Typography variant='h5' css={story_category}>Story</Typography>
						<Typography component='h1' color='textPrimary' variant='h2' css={story_title}>入浴を通して、<br css={pc_hide} />毎日を豊かに。</Typography>
						<Typography css={story_description}>
							『Onsen*』（オンセン）は、入浴を通してお客様が毎日豊かな生活を<br css={sp_hide} />過ごしてほしい。<br css={pc_hide} />
							そんな想いを込めた入浴ヘルスケアブランドです。コロナ禍以降、<br
							css={sp_hide} />メンタルヘルスや身体の不調を訴える方が増えています。「お風呂」というあなただけの、<br css={sp_hide} />そして身近な場所で日々の生活習慣から心身を整えてみませんか。私たちは<br
							css={sp_hide} />オリジナルの入浴レシピや、環境音+便利な入浴タイマー、専用の入浴剤などの<br css={sp_hide} />提供を通じて、入浴を「睡眠への導入として心身を整えるもの」<br
							css={sp_hide} />と位置づけ、毎日がんばるあなたをサポートします。
						</Typography>
					</article>
				</Container>
			</BackgroundImage>

			<Container css={container}>
				{/*	ブランドに込めた想い */}
				<article css={[article, article_flex, article_flex_reverse]}>
					<div css={article_main}>
						<div css={brand_logo}>
							<BrandLogo />
						</div>
					</div>
					<div css={article_sub}>
						<Typography color='textPrimary' css={article_title}>ブランドに込めた想い<br />「<span
							css={primary_text}>湧き出す</span>」</Typography>
						<Typography variant='body1' css={article_text}>
							アスタリスクをベースに放射状に広がる形は、間欠泉や温泉が水を湧き出す様子を表現しています。
							サービスを通して、使う人の活力が内から湧き出してくるようなものにしていきたいという意思を込めました。
							<br /><br />
							特徴的なコバルトブルーは、大分県別府市より「海地獄」と呼ばれる湯色から。源泉数が日本一で、入浴剤の成分のベースでもある別府温泉の中でも一際目立つ海地獄は、美しい見た目に反して泉温が98度もあり、神秘性と熱量を孕んだ日本古来の名高い泉源の1つです。
						</Typography>
					</div>
				</article>
				{/*	良質な入浴と睡眠へのこだわり */}
				<article css={[article, article_flex]}>
					<div css={[article_main]}>
						<BathGraphStoryFigure css={[bath_graph_figure, bg_grey]} />
						<Typography color='textSecondary' css={bath_graph_note}>入浴と睡眠の相関イメージ</Typography>
					</div>
					<div css={article_sub}>
						<Typography color='textPrimary' css={article_title}>良質な入浴と<br />睡眠へのこだわり<span
							css={sp_hide}>。</span></Typography>
						<Typography variant='body1' css={article_text}>
							「Onsen*」は毎日の入浴と睡眠に着目しています。
							<br /><br />
							別府温泉発の入浴剤「薬用入浴剤 デイリーミネラル」が身体を温め、温泉療法専門医・早坂信弥監修のアプリ「Onsen*」が、最適な入浴方法と最適な入眠時間をサポート。あなたを深い眠りへと導きます。
							<br /><br />
							「薬用入浴剤
							デイリーミネラル」をお風呂に入れることで、温泉成分が溶け出し、水道水に比べまろやかな入り心地になります。再現されるのはpH9.6の弱アルカリ湯質。「美肌の湯」と言われる湯に似た、クセがなく多くの方に好まれる湯質です。お湯はツルツル、湯上りしっとり、中ぽかぽか。有効成分のミネラル皮膜が身体を包み、湯冷めを防ぎつつ芯から温めてくれます。これが、独自の爽快感そして湯上り感をもたらしてくれます。
							<br /><br />
							そして、深い眠りには「深部体温」と呼ばれる、深部の体温が鍵となります。入浴には、「深部体温」を一時的に引き上げる効果があり、引き上げられた体温が放熱されると、やがて眠気が訪れます。この体験を引き出すサポートを、「Onsen*」は行っています。
						</Typography>
						<Link to='/pages/app'>
								<Button css={text_link}>アプリを見る</Button>
						</Link>
					</div>
				</article>
			</Container>

			{/*	大事にしていること */}
			<div css={bg_grey}>
				<Container css={container}>
					<article css={article}>
						<Typography color='textPrimary' css={concept_title}>大事にしていること</Typography>
						<ul>
							<li css={concept_list_item}>
								<Typography css={concept_list_title}>1. 湯治を現代に</Typography>
								<Typography color='textSecondary' css={concept_list_text}>入浴は、心身を整える「湯治」に近い作用があります。<br
									css={sp_hide} />私たちは、毎日の何気ない入浴を「現代の湯治」としてデザインし、あなたの生活を豊かにします。</Typography>
							</li>
							<li css={concept_list_item}>
								<Typography css={concept_list_title}>2. 内なる力を開放する</Typography>
								<Typography color='textSecondary' css={concept_list_text}>私たちの体には、自然治癒力があります。薬に頼らずに、内側から身体を高められるよう、<br
									css={sp_hide} />入浴を通して湧き上がる本来の活力を大事にしたいと考えています。</Typography>
							</li>
							<li css={concept_list_item}>
								<Typography css={concept_list_title}>3. 自分と向き合う</Typography>
								<Typography color='textSecondary' css={concept_list_text}>情報に溢れる現代で、私たちは常に繋がり、ひとりになれる時間を失いがちです。<br
									css={sp_hide} />Onsen*は、自分と向き合い安らぐ時間を、あなたの暮らしに創出します。</Typography>
							</li>
						</ul>
					</article>
				</Container>
			</div>

			{/*	会社概要 */}
			<Container css={container}>
				<article css={[article, article_flex]}>
					<div css={article_sub}>
						<Typography color='textPrimary' css={section_title}>会社概要</Typography>
					</div>
					<div css={article_main}>
						<ContentsMenu
							css={contents_menu}
							items={[
								{
									title: '会社名',
									description: `株式会社アジケ`,
								},
								{
									title: '本社所在地',
									description: `〒153-0042<br/>東京都目黒区青葉台3-10-9 VORT青葉台4階`,
								},
								{
									title: '代表者',
									description: `代表取締役 梅本周作`,
								},
								{
									title: '設立',
									description: `2007年8月27日`,
								},
								{
									title: '事業内容',
									description: `サービスデザイン・プロダクト開発・入浴剤ならびに入浴サービス企画開発`,
								},
							]}
						/>
					</div>
				</article>
			</Container>

			{/*	今すぐ体験しましょう */}
			<div css={bg_primary}>
				<Container css={container}>
					<ServiceList css={article}/>
				</Container>
			</div>

			{/*	News */}
			<NewsList />
		</>
	)
}

export default Story
