import React from 'react'
import Story from '~/components/pages/Story'
import SEO from '~/utils/seo'
import { PageProps } from 'gatsby'

export default function StoryPage({ location }: PageProps) {
    return (
        <>
            <SEO
                title='ストーリー・会社概要 | Onsen*(オンセン)'
                description='入浴を通して、毎日を豊かに。『Onsen*』（オンセン）は、入浴を通してお客様が毎日豊かな生活を過ごしてほしい。そんな想いを込めた入浴ヘルスケアブランドです。コロナ禍以降、メンタルヘルスや身体の不調を訴える方が増えています。「お風呂」というあなただけの、そして身近な場所で日々の生活習慣から心身を整えてみませんか。私たちはオリジナルの入浴レシピや、環境音+便利な入浴タイマー、専用の入浴剤などの提供を通じて、入浴を「睡眠への導入として心身を整えるもの」と位置づけ、毎日がんばるあなたをサポートします。'
                url={location.href}
            />
            <Story />
        </>
    )
}
