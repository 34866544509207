import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const item_ = css`
	margin-bottom: 1px;
	
	background: ${palette.grey['500']};
	padding: 13px 16px;

	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
	}
`

export const item_first = css`
	border-radius: 8px 8px 0 0;
`

export const item_end = css`
	margin: 0;
	border-radius: 0 0 8px 8px;
`

export const title = css`
	font-size: 1.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 40%;
		font-size: 1.6rem;
	}
`

export const description = css`
	margin-top: 10px;
	
	font-size: 1.4rem;
	font-weight: 500;
	@media (min-width: ${breakpoints.tablet}px) {
		margin: 0;
		
		width: 60%;
		font-size: 1.6rem;
	}
`