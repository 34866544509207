import React from 'react'
import Typography from '~/components/atoms/Typography'

import { item_, item_first, item_end, title, description } from './styles'

type item = {
	title: string
	description: string
}

type Props = {
	items: item[]
}

const ContentsMenu: React.VFC<Props> = ({ items, ...props }) => {
	return (
		<ul {...props}>
			{items.map((item, index) => {
				return (
					<li css={[item_, index === 0 && item_first, index === items.length - 1 && item_end]} key={item.title}>
						<Typography color='textPrimary' css={title}>{item.title}</Typography>
						<Typography dangerouslySetInnerHTML={{__html: `${item.description}`}} css={description} />
					</li>
				)
			})}
		</ul>
	)
}

export default ContentsMenu