import { css } from '@emotion/core'

import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const pc_hide = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const sp_hide = css`
	@media (max-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const container = css`
	padding-top: 20px;
	padding-bottom: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
`

export const article = css`
	padding: 20px 0;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 40px 20px;
	}
	@media (min-width: ${breakpoints.pc}px) {
		padding: 40px 0;
	}
`

export const article_flex = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
		justify-content: space-between;
		align-items: start;
	}
`

export const article_flex_reverse = css`
	@media (min-width: ${breakpoints.tablet}px) {
		flex-direction: row-reverse;
	}
`

export const article_main = css`
	position: relative;
	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(58% - 30px);
	}
`

export const article_sub = css`
	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(42% - 30px);
	}
`

export const article_title = css`
	margin-top: 20px;

	font-size: 2.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;

		font-size: 3.2rem;
	}
`

export const article_text = css`
	margin-top: 10px;
	font-weight: 500;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
`

export const primary_text = css`
	color: ${palette.primary.main};
`

export const bg_grey = css`
	background-color: ${palette.grey['500']};
`

export const bg_primary = css`
	background-color: ${palette.primary.main};
`

export const bg_story = css`
    ::before, ::after {
	    background-color: rgba(255, 255, 255, 0.8);
	    background-blend-mode: lighten;
    }
`

export const story = css`
    max-width: 1000px;
`

export const story_category = css`
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const story_title = css`
	margin-top: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
		text-align: center;
	}
`

export const story_description = css`
	margin-top: 20px;

	font-size: 2rem;
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
		font-size: 2.2rem;
	}
`

export const brand_logo = css`
	padding: 70px;
	display: grid;
	place-items: center center;
	border: 1px solid ${palette.grey['400']};
	border-radius: 12px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 20%;
	}
`

export const bath_graph_figure = css`
	margin-left: -20px;
	margin-right: -20px;
	border-radius: 0;
	@media (min-width: ${breakpoints.tablet}px) {
		margin: 0;

		border-radius: 20px;
	}
`

export const bath_graph_note = css`
	margin-top: 10px;
	text-align: right;

	font-weight: 500;
	font-size: 1.2rem;
`

export const recommend_title = css`
	color: ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const recommend_description = css`
	margin-top: 20px;

	color: ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const concept_title = css`
	font-size: 2.8rem;

	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 4rem;
		text-align: center;
	}
`

export const concept_list_item = css`
	margin-top: 20px;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;

		text-align: center;
	}
`

export const concept_list_title = css`
	font-size: 2rem;

	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2.4rem;
	}
`

export const concept_list_text = css`
	margin-top: 10px;
	font-weight: 500;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
		font-size: 2rem;
	}
`

export const service_list = css`
	margin-top: 20px;

	display: grid;
	gap: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;

		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 40px;
	}
`

export const service_card = css``

export const campaign_card = css`
	margin-top: 20px;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
`

export const section_title = css`
	font-size: 2.4rem;

	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 3.2rem;
	}
`

export const dual_link = css`
	margin-top: 20px;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
`

export const contents_menu = css`
	margin-top: 20px;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
	}
`

export const header_container = css`
	position: relative;
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const list_x_scroll = css`
	display: flex;
	overflow-x: scroll;
	margin-top: 20px;

	:after {
		min-width: 20px;
		height: 1px;
		content: '';
	}

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
		justify-content: space-between;
		:after {
			display: none;
		}
	}
`

export const news_card = css`
	margin-right: 10px;
	width: calc(100% - 45px);
	min-width: calc(100% - 45px);
	@media (min-width: ${breakpoints.tablet}px) {
		min-width: auto;
		width: 334px;
	}
`

export const news_link_text = css`
	position: absolute;
	right: 0;
	bottom: 0;
`

export const news_title = css`
	margin-top: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
`

export const text_link = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
`
