import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const container = css`
	padding: 11px 20px 20px 20px;
	border-radius: 20px;
	overflow: hidden;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 31px 40px 40px 40px;
	}
`

export const header = css`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`

export const header_title = css`
	font-size: 1.4rem;
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 2rem;
	}
`

export const header_sub_text = css`
	font-size: 1.2rem;
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 1.4rem;
	}
`

export const bar_container = css`
	position: relative;
	margin-top: 16px;
`

export const bar = css`
	height: 6px;
	border-radius: 4px;
	background-color: ${palette.primary.main};
`

export const circle = css`
	width: 16px;
	height: 16px;
	border-radius: 10px;
	background-color: ${palette.primary.main};
`

export const start_circle = css`
	position: absolute;
	top: -5px;
	left: 0;
`

export const middle_circle = css`
	position: absolute;
	top: -5px;
	left: calc(50% - 8px);
`

export const end_circle = css`
	position: absolute;
	top: -5px;
	right: 0;
`

export const fig1_container = css`
`

export const fig1_header = css`
	position: relative;
	background: ${palette.common.white};
	border-radius: 12px 12px 0 0;
	padding: 23px 20px 30px 20px;
	margin-top: 25px;
	margin-bottom: 1px;
`

export const fig1_center_text = css`
	position: absolute;
	top: calc(50% - 12.8px);
	left: calc(50% - 31px);
	color: ${palette.primary.main};
	font-size: 2rem;
`

export const fig1_right_text = css`
	position: absolute;
	top: calc(50% - 12.8px);
	right: 20px;
	font-size: 2rem;
`

export const fig1_footer = css`
	background: ${palette.common.white};
	border-radius: 0 0 12px 12px;
	padding: 20px;
	display: flex;
	align-items: center;
`

export const fig1_footer_text = css`
	margin-left: 10px;
	padding-left: 1rem;
	text-indent: -1.4rem;
`

export const fig1_icon = css`
	width: 26px;
	margin-left: 14px;
`

export const fig2_container = css`
	margin-top: 10px;
	padding: 20px;
	background: ${palette.common.white};
	border-radius: 12px;
	display: flex;
	align-items: start;
`

export const fig2_left = css`
	margin-left: 14px;
	flex: auto;
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
		align-items: flex-start;
	}
`

export const fig2_text_container = css`
	@media (min-width: ${breakpoints.tablet}px) {
		width: 45%;
	}
	@media (min-width: ${breakpoints.pc}px) {
		flex: none;
	}
`

export const fig2_text = css`
	margin-top: 12px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 13px;
	}
`

export const fig2_icon = css`
	width: 32px;
`

export const notice_box = css`
	color: ${palette.primary.main};
	padding: 13px 12px;
	border: 1px solid ${palette.primary.main};
	border-radius: 8px;
	position: relative;
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
		margin-left: 10px;
		width: 55%;
	}
	@media (min-width: ${breakpoints.pc}px) {
		width: 100%;
	}
`

export const notice_patch = css`
	position: absolute;
	top: -6px;
	right: -6px;
	width: 12px;
	height: 12px;
	background: ${palette.primary.light};
	clip-path: circle(6px at 50% 50%);
`

export const fig_bath_container = css`
	margin-top: 10px;
	padding: 20px;
	background: ${palette.common.white};
	border-radius: 12px;
	display: flex;
	align-items: start;
`

export const fig_bath_icon = css`
	width: 32px;
`

export const fig_bath_list_item = css`
	margin-top: 12px;
`

export const fig_bath_text_list = css`
	margin-left: 14px;
`